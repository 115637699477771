.Footer {
  /* position: fixed; */
  margin-top: 10px;
  bottom: 0;
  z-index: 100;
}

#Footer-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: white;
}

#contact-footer {
  margin-top: 20px;
}

#txtCopyright {
  color: black;
}