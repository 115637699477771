a {
  text-decoration: none;
}

/* ############################################################## */
/*                              new                               */
/* ############################################################## */

.catalog-container {
  margin: 20px 0 60px 0;
}

.catalog-sidebar {
  margin: 0px;
  padding: 15px;
  color: white;
  background-color: #729db8;
  border: solid 1px #5c89a5;

}

.catalog-menu {
  padding: 10px;
  background-color: #ffffff;
  border: solid 1px #c2c2c2;
}

.catalog-menu-item {
  color: #a0a0a0;
  font-size: 1.0rem;
}

.goldp-container {
  background-color: #212d94;
  border-radius: 5px;
  padding: 8px;
  font-size: 1.2rem;
}

.goldp-header {
  background-color: white;
  text-align: center;
  font-size: 1.1rem;
}

.goldp-content {
  background-color: #89cef8;
  text-align: center;
  font-size: 1.2rem;
  border-bottom: 0;
}

.goldp-table {
  margin: auto;
}