@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
  --color-main: #d3bd7a;
  --color-main-dark: #ab832f;
  --color-main-light: #f6edd4;
}

#root {
  min-height: 100vh;
}